import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  token: null,
  isLoading: false,
  error: null,
  spins: null,
  receipts: [],
  vouchers: [],
  points: null,
  user: null,
  spinData: null,
  referrer: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveUser: (state, action) => {
      // console.log(action.payload);
      return {
        ...state,
        user: action.payload,
      };
    },
    logoutUser: (state, action) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user-token");
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        user: null,
      };
    },
    savePoints: (state, action) => {
      return {
        ...state,
        points: action.payload,
      };
    },
    saveReceipts: (state, action) => {
      return {
        ...state,
        receipts: action.payload,
      };
    },
    saveVouchers: (state, action) => {
      return {
        ...state,
        vouchers: action.payload,
      };
    },
    saveToken: (state, action) => {
      localStorage.setItem("user-token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    },
    saveReferrer: (state, action) => {
      return {
        ...state,
        referrer: action.payload,
      }
    }
  },
});

export const {
  saveStore,
  saveUser,
  logoutUser,
  savePoints,
  saveToken,
  saveStores,
  saveReceipts,
  saveVouchers,
  saveReferrer
} = userSlice.actions;
export default userSlice.reducer;
